import { JOIN_NOW_BG, JOIN_NOW_BLOCK, JOIN_NOW_BTN_BG, BANNER_BG } from 'assets/images'
import styled from 'styled-components'

const StyledHome = styled.div`
  .view-btn {
    text-decoration: none;

    span {
      font-size: 18px;
    }

    img {
      width: 11px;
      height: 8px;
      object-fit: contain;
      margin-left: 4px;
    }
  }

  .top-section {
    margin-top: 17px;
    margin-bottom: 32px;

    .banner {
      border-bottom: 3px solid #246b7d;
      transform: translateY(-14px);
      ${({ theme }) => theme.media.lessThan(theme.size.xxl)} {
        transform: translateY(-63px);
      }

      .banner-background {
        background-image: url(${BANNER_BG});
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        aspect-ratio: 1160 / 613;

        .banner-logo {
          width: calc(100% * 669 / 1160);
          height: calc(100% * 255 / 613);
          top: calc(100% * 128 / 613);

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .video-container {
      .video {
        height: 0;
        position: relative;
        overflow: hidden;
        padding: 0 0 56.25%;
        width: 100%;
        border-radius: 16px;
        
        .twitch-video {
          position: absolute;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .bg-fluid {
    ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
      background-position-y: 92%;
    }

    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      background-image: none;
    }

    .open-race-section {
      margin-bottom: 130px;

      ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
        margin-bottom: 80px;
      }

      .head {
        margin-bottom: 40px;

        ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
          margin-bottom: 14px;
        }

        .title {
          font-size: 36px;
          line-height: 26px;

          ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
            font-size: 24px;
            line-height: 20px;
          }
        }
      }

      .content {
        margin-bottom: 50px;
        min-height: 326px;

        ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
          row-gap: 24px;
        }
      }

      .class-category {
        column-gap: 10px;

        .arrow-left {
          cursor: pointer;
        }

        .arrow-right {
          cursor: pointer;
        }

        .category-content {
          column-gap: 12px;

          ${({ theme }) => theme.media.lessThan(theme.size.md)} {
            flex-wrap: wrap;
            row-gap: 12px;
          }
        }
      }
    }
  }

  .join-now-fluid-bg {
    background-image: url(${JOIN_NOW_BG});
    margin-bottom: 120px;

    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      margin-bottom: 80px;
    }

    .container {
      padding: 60px 0;

      ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
        padding: 40px 0;
      }

      .join-now {
        background-image: url(${JOIN_NOW_BLOCK});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        padding: 60px 230px;

        ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
          padding: 20px 140px;
        }

        ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
          background-size: cover;
        }

        .content {
          .title {
            text-transform: uppercase;
            font-size: 44px;
            line-height: 52.8px;

            margin-bottom: 20px;

            ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
              font-size: 34px;
              margin-bottom: 6px;
            }

            ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
              font-size: 28px;
              line-height: 30px;
            }

            ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
              margin-bottom: 14px;
            }
          }

          .input-name {
            border: none;
            border-bottom: 1px solid ${({ theme }) => theme.color.white};
            background-color: ${({ theme }) => theme.color.transparent};

            font-size: 16px;
            line-height: 19.2px;

            width: 386px;
            margin-bottom: 28px;

            &:focus {
              outline: none;
            }

            ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
              width: 280px;
            }
          }

          .join-now-btn {
            background-color: ${({ theme }) => theme.color.transparent};
            border: none;
            background-image: url(${JOIN_NOW_BTN_BG});
            background-size: contain;
            background-repeat: no-repeat;

            margin-top: 20px;
            font-size: 24px;
            line-height: 28px;

            width: 172px;
            height: 50px;

            ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
              font-size: 20px;

              width: 150px;
              height: 44px;
            }

            ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
              font-size: 16px;

              width: 110px;
              height: 32px;
            }

            img {
              bottom: -1px;
              right: 23px;

              width: 21px;
              height: 6px;
              object-fit: contain;

              ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
                right: 19px;
              }

              ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
                right: 14px;

                width: 16px;
                height: 5px;
              }
            }
          }
        }

        .horse-place {
          .horse {
            bottom: 53px;
            right: 105px;

            width: 443px;
            height: 332px;
            object-fit: contain;

            ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
              bottom: 10px;
              width: 40%;
            }
          }

          .shadow {
            bottom: 46px;
            right: 20px;

            ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
              bottom: 22px;
              width: 40%;
            }
          }
        }
      }
    }
  }

  .top-horse-section {
    margin-bottom: 120px;

    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      margin-bottom: 80px;
    }

    .top-horse {
      .top-horse-title {
        font-size: 36px;
        line-height: 26px;

        margin-bottom: 60px;

        ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
          font-size: 24px;
          margin-bottom: 30px;
        }
      }

      .content {
        ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
          row-gap: 46px;
        }
      }
    }
  }

  .top-stable-section {
    margin-bottom: 104px;

    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      margin-bottom: 80px;
    }

    .top-stable {
      .top-stable-title {
        font-size: 36px;
        line-height: 26px;

        margin-bottom: 30px;

        ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
          font-size: 24px;
          margin-bottom: 30px;
        }
      }

      .content {
        ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
          row-gap: 46px;
        }
      }
    }
  }

  .top-horse-head {
    position: relative;
    a {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      font-size: 18px;
      text-decoration: none;

      &:hover {
        color: #fff;
      }
    }

    img.link {
      width: 11px;
      height: 8px;
      object-fit: contain;
      margin-left: 4px;
    }
  }
`

export default StyledHome
