/* eslint-disable @typescript-eslint/no-explicit-any */
import userApi from 'apis/userApi'
import { constants, links, paths } from 'apps'
import { CURRENCY_TYPE } from 'apps/constants'
import {
  GAME_TOKEN_KUDA,
  GAME_TOKEN_MERAH,
  ICON_ADIL,
  ICON_TON_CHAIN,
  ICON_ARROW,
  ICON_GAME_PAD,
  NEXT_RACE_BOTTOM_FRAME,
  TWO_LINE
} from 'assets/images'
import { ethers } from 'ethers'
import { setCoinUser } from 'features/Balance/coinUser.slice'
// import ExchangeMERAModal from 'features/Balance/components/ExchangeMAREModal'
// import ModalBirutoMare from 'features/Balance/components/ModalBiruToMare'
import ModalKudaToAdil from 'features/Balance/components/ModalKudaToAdil'
// import ModalMerahToMare from 'features/Balance/components/ModalMerahToMare'
import ModalMerahToAdil from 'features/Balance/components/ModalMerahToAdil'
import SwapMERAHToKUDAModal from 'features/Balance/components/SwapMERAHToKUDAModal'
import { setCurrentUser } from 'features/Profile/profile.slice'
import { ResultHorseModal } from 'features/Race/components'
import { useAppDispatch, useAppSelector, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { Coin, CoinUser } from 'models'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// import { format5Decimals, handleAsyncRequest } from 'utils/helper'
import { handleAsyncRequest } from 'utils/helper'
import { getCurrentUser, getSigner } from 'utils/metamask'
import StyledBalance from './styled'
import SwapADILToKUDAModal from 'features/Balance/components/SwapADILToKUDAModal'

export default function Balance() {
  // const [isModalExchangeOpen, toggleIsExchangeModalOpen] = useToggle(false)
  const [isModalWithdrawOpen, toggleIsWithdrawModalOpen] = useToggle(false)
  const [isKudaWithdrawAdilModalOpen, toggleIsKudaWithdrawAdilModalOpen] = useToggle(false)
  // const [isModalWithdrawFreeOpen, toggleIsWithdrawFreeModalOpen] = useToggle(false)
  const [openModalConfirmOpenBeta, setOpenModalConfirmOpenBeta] = useState(false)
  const [isSwapMERAHToKUDAModalOpen, toggleIsSwapMERAHToKUDAModalOpen] = useToggle(false)
  const [isSwapADILToKUDAModalOpen, toggleIsSwapADILToKUDAModalOpen] = useToggle(false)
  const { auth } = useAppSelector(state => state)
  const { coinUser } = useAppSelector(state => state)
  const [coinKuda, setCoinKuda] = useState<number>(0)
  const [coinMerah, setCoinMerah] = useState<number>(0)
  // const [coinBiru, setCoinBiru] = useState<number>(0)
  const [coinADIL, setCoinADIL] = useState<number>(0)
  const [isOpenModalConfirmWithDraw, setOpenModalConfirmWithDraw] = useState<boolean>(false)
  const [titleBalace, setTitleBalance] = useState<string>('')

  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const [isOpenModalNoticeMetamask, setOpenModalNoticeMetamask] = useState<boolean>(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const rejectMessageFailed = t(`${NOTIFICATION_MESSAGE}.transactionFailed`)
  const messageMetamask = t(`${NOTIFICATION_MESSAGE}.confirmTransaction`)
  const messageConfirmOpenBeta = t(`${NOTIFICATION_MESSAGE}.confirmVersion`)

  // const openExchangeMARE = () => {
  //   toggleIsExchangeModalOpen(true)
  // }

  const openSwapADILToKUDA = () => {
    toggleIsSwapADILToKUDAModalOpen(true)
  }

  const openSwapMERAHToKUDA = () => {
    toggleIsSwapMERAHToKUDAModalOpen(true)
  }

  const closeSwapMERAHToKUDAModalOpen = () => {
    toggleIsSwapMERAHToKUDAModalOpen(false)
  }

  const closeSwapADILToKUDAModalOpen = () => {
    toggleIsSwapADILToKUDAModalOpen(false)
  }

  // const coinHtcDisplayScreen = handleGroupingsOfThreeNumbers(Number(auth.balance.coinMare || 0) / Number(Math.pow(10, 18)))

  // const coinMare = format5Decimals((Number(auth.balance.coinMare || 0) / Number(Math.pow(10, 18))).toString())
  // const coinMare = coinHtcDisplayScreen.split('.') ? coinHtcDisplayScreen.split('.').shift() : coinHtcDisplayScreen
  // const coinMareLogic = Number(auth.balance.coinMare) / Number(Math.pow(10, 18))
  const coinADILLogic = Number(auth.balance.coinMare) / Number(Math.pow(10, 18))

  const fetchCoinUser = useCallback(async () => {
    const [error, resultCoinUser]: any = await handleAsyncRequest(userApi.getUserItems())
    if (error?.code === 403) {
      window.location.href = paths.auth.login()
    }
    if (!resultCoinUser) return
    const handleCoinUser = (data: CoinUser) => {
      data.items.filter((item: Coin) => {
        if (item.item_type === CURRENCY_TYPE.KUDA) {
          setCoinKuda(item.amount)
        }

        if (item.item_type === CURRENCY_TYPE.MERAH) {
          setCoinMerah(item.amount)
        }

        // if (item.item_type === CURRENCY_TYPE.BIRU) {
        //   setCoinBiru(item.amount)
        // }
      })
    }

    handleCoinUser(resultCoinUser.data)
  }, [])

  useEffect(() => {
    const checkCurrentCoinUser = async () => {
      const [, currentUser]: any = await handleAsyncRequest(getCurrentUser())
      if (!currentUser) return

      const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
      if (!resultCoinUser) return

      dispatch(setCurrentUser(currentUser))
      dispatch(setCoinUser(resultCoinUser.data))
    }

    checkCurrentCoinUser()
    fetchCoinUser()
    getCoinADIL()
  }, [])

  useEffect(() => {
    if (coinUser) {
      coinUser?.items?.map((item: any) => {
        if (item.item_type === 'MERAH_NEW') {
          setCoinMerah(() => item?.amount || 0)
        } else if (item.item_type === 'KUDA_NEW') {
          setCoinKuda(() => item?.amount || 0)
        }
      })
    }
  }, [coinUser])

  const handleOk = () => {
    setOpenModalConfirmWithDraw(false)
  }

  const handleOkMetamask = () => {
    localStorage.removeItem(constants.SIGNER)
    setOpenModalNoticeMetamask(false)
  }

  const checkWithDrawEMAS = async (type: string) => {
    setTitleBalance('warning')
    // const [error, resultCoinUser]: any = await handleAsyncRequest(userApi.getUserItems())
    // if (error?.code === 403) {
    //   window.location.href = paths.auth.login()
    // }
    // if (!resultCoinUser) return
    // if (resultCoinUser?.data?.pending_transactions?.length > 0) {
    //   setOpenModalConfirmWithDraw(true)
    //   setTitleBalance('warning')
    // } else {
    //   switch (type) {
    //     case CURRENCY_TYPE.MERAH: {
    //       toggleIsWithdrawModalOpen(true)
    //       break
    //     }
    //     case CURRENCY_TYPE.KUDA: {
    //       toggleIsKudaWithdrawAdilModalOpen(true)
    //       break
    //     }
    //     default:
    //       // toggleIsWithdrawFreeModalOpen(true)
    //       break
    //   }
    // }

    switch (type) {
      case CURRENCY_TYPE.MERAH: {
        toggleIsWithdrawModalOpen(true)
        break
      }
      case CURRENCY_TYPE.KUDA: {
        toggleIsKudaWithdrawAdilModalOpen(true)
        break
      }
      default:
        // toggleIsWithdrawFreeModalOpen(true)
        break
    }
  }

  useEffect(() => {
    const checkSignerBalance = async () => {
      const [signerError] = await handleAsyncRequest(getSigner())
      if (signerError) {
        navigate(links.home.index())
      }
    }

    checkSignerBalance()
  }, [])

  const clickBtnOkOpenBeta = () => {
    setOpenModalConfirmOpenBeta(false)
  }

  const getCoinADIL = async () => {
    const addressUser = await signer.getAddress()
    const balance = await provider.getBalance(addressUser)
    const data = +ethers.utils.formatEther(balance)
    setCoinADIL(+data?.toFixed(5) || 0)
  }

  const layoutCoin = (logo: string, name: string, price: string | number) => {
    return (
      <div className='wallet-item'>
        <div>
          <img src={logo} alt='' className='icon-coin' />
        </div>
        <div className='name-coin'>{name}</div>
        <div className='value-coin'>{price}</div>
      </div>
    )
  }

  const layoutExchange = (logoFrom: string, onClickItem: () => void, logoTo: string) => {
    return (
      <div className='exchange-container' onClick={onClickItem}>
        <div className='background-line'>
          <img src={NEXT_RACE_BOTTOM_FRAME} alt='' width={300} />
        </div>
        <div className='exchange-item'>
          <div className='exchange-background'>
            <div>
              <img src={logoFrom} alt='' className='icon-coin' />
            </div>
            <div className='name-coin'>
              <img src={ICON_ARROW} alt='' className='icon-coin' />
            </div>
            <div>
              <img src={logoTo} alt='' className='icon-coin' />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <StyledBalance>
      <div className='container'>
        <div className='balance-container'>
          <div className='balance'>
            <div className='title color-primary font-bold'>{t(`${NOTIFICATION_MESSAGE}.balance`)}</div>
            <img src={TWO_LINE} alt='' className='line' />
          </div>

          <div className='wallet'>
            <div>
              <div className='wallet-title'>
                <img src={ICON_TON_CHAIN} alt='' className='wallet-icon' width={25} />
                {t(`${NOTIFICATION_MESSAGE}.tonChainCoin`)}
              </div>
              {layoutCoin(ICON_TON_CHAIN, CURRENCY_TYPE.TON, coinADIL)}
              {/* {layoutCoin(CHAIN_TOKEN_MARE, CURRENCY_TYPE.MARE, coinMare ?? '0')} */}
            </div>
            <div>
              <div className='wallet-title'>
                <img src={ICON_GAME_PAD} alt='' className='wallet-icon' width={20} />
                {t(`${NOTIFICATION_MESSAGE}.gameCoin`)}
              </div>
              {layoutCoin(GAME_TOKEN_KUDA, CURRENCY_TYPE.ETH, coinKuda ?? '0')}
              {layoutCoin(GAME_TOKEN_MERAH, CURRENCY_TYPE.BTC, coinMerah ?? '0')}
              {/* {layoutCoin(GAME_TOKEN_BIRU, CURRENCY_TYPE.BIRU, coinBiru ?? '0')} */}
            </div>
          </div>

          <div className='exchange'>
            <div>
              {/* <div className='exchange-title'>{t(`${NOTIFICATION_MESSAGE}.exchange`)} {CURRENCY_TYPE.KUDA}</div>
              {layoutExchange(CHAIN_TOKEN_MARE, openExchangeMARE, GAME_TOKEN_KUDA)} */}
              <div className='exchange-title'>
                {t(`${NOTIFICATION_MESSAGE}.exchange`)} {CURRENCY_TYPE.ETH}
              </div>
              {layoutExchange(ICON_TON_CHAIN, openSwapADILToKUDA, GAME_TOKEN_KUDA)}
              {layoutExchange(GAME_TOKEN_MERAH, openSwapMERAHToKUDA, GAME_TOKEN_KUDA)}
              <div className='exchange-note'>{t(`${NOTIFICATION_MESSAGE}.non_transaction`)}</div>
            </div>
            {/* START TODO 20230110 comment source code claim mare */}
            <div>
              {/* <div className='exchange-title'>
                {`CLAIM ${CURRENCY_TYPE.MARE}`}
              </div>
              {layoutExchange(GAME_TOKEN_MERAH, () => checkWithDrawEMAS(CURRENCY_TYPE.MERAH), CHAIN_TOKEN_MARE)} */}
              {/* <div style={{ height: '148px' }}></div> */}
              <div>
                <div className='exchange-title'>{`CLAIM ${CURRENCY_TYPE.TON}`}</div>
                {layoutExchange(GAME_TOKEN_MERAH, () => checkWithDrawEMAS(CURRENCY_TYPE.MERAH), ICON_TON_CHAIN)}
                {layoutExchange(GAME_TOKEN_KUDA, () => checkWithDrawEMAS(CURRENCY_TYPE.KUDA), ICON_TON_CHAIN)}
              </div>
            </div>
            {/* END TODO 20230110 comment source code claim mare  */}
          </div>
        </div>
      </div>

      {isOpenModalConfirmWithDraw && (
        <ResultHorseModal
          title={titleBalace}
          onOk={handleOk}
          message={<p className='reject-msg-failed'>{rejectMessageFailed}</p>}
          exchangeCoin={true}
        />
      )}

      {isOpenModalNoticeMetamask && (
        <ResultHorseModal
          title={titleBalace}
          onOk={handleOkMetamask}
          message={<p>{messageMetamask}</p>}
          exchangeCoin={true}
        />
      )}

      {openModalConfirmOpenBeta && (
        <ResultHorseModal
          title={titleBalace}
          onOk={clickBtnOkOpenBeta}
          message={<p>{messageConfirmOpenBeta}</p>}
          exchangeCoin={true}
        />
      )}

      {isModalWithdrawOpen && (
        <ModalMerahToAdil
          toggleIsWithdrawModalOpen={toggleIsWithdrawModalOpen}
          coinADILLogic={coinADILLogic}
          coinEmas={coinADIL}
          coinMerah={coinMerah}
        />
      )}

      {isKudaWithdrawAdilModalOpen && (
        <ModalKudaToAdil
          toggleIsKudaWithdrawAdilModalOpen={toggleIsKudaWithdrawAdilModalOpen}
          coinADILLogic={coinADILLogic}
          coinEmas={coinADIL}
          coinKuda={coinKuda}
        />
      )}

      {/* {isModalWithdrawFreeOpen && (
        <ModalBirutoMare
          toggleIsWithdrawModalOpen={toggleIsWithdrawFreeModalOpen}
          coinMareLogic={coinMareLogic}
          coinEmas={coinMare}
          coinBiru={coinBiru}
        />
      )} */}

      {/* {isModalExchangeOpen && (
        <ExchangeMERAModal
          toggleIsExchangeModalOpen={toggleIsExchangeModalOpen}
          coinMare={coinMare}
          coinMareLogic={coinMareLogic}
          coinKuda={coinKuda}
        />
      )} */}
      {isSwapMERAHToKUDAModalOpen && (
        <SwapMERAHToKUDAModal
          onCloseButtonClick={closeSwapMERAHToKUDAModalOpen}
          coinKuda={coinKuda}
          coinMerah={coinMerah}
        />
      )}

      {isSwapADILToKUDAModalOpen && (
        <SwapADILToKUDAModal
          onCloseButtonClick={closeSwapADILToKUDAModalOpen}
          coinKuda={coinKuda}
          coinAdil={coinADIL}
        />
      )}
    </StyledBalance>
  )
}
