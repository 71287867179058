/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import exchangeApi from 'apis/exchange'
import { constants } from 'apps'
import { CURRENCY_TYPE, STATUS_TRANSACTION, timeCheckNonce } from 'apps/constants'
import {
  CIRCEL_ARROW_DOWN_PRIMARY,
  CLOSE_BTN,
  CONNECT_WALLET_EXCHANGE,
  DISABLE_CONNECT_WALLET_EXCHANGE,
  GAME_TOKEN_KUDA,
  ICON_ADIL,
  ICON_TON_CHAIN,
  UP
} from 'assets/images'
import { ResultHorseModal } from 'features/Race/components'
import { useAppDispatch, useDebounce, useReloadCurrentPage, useToggle, useUpdateEffect } from 'hooks'
import { LENDING_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import { SwapETokenRequest, resultItemNFT } from 'models'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'shared'
import { convertGasPrice, handleAsyncRequest, justNumbers } from 'utils/helper'
import InProgressBalanceModal from 'features/Balance/components/InProgress'
import SwapADILToKUDAModalStyled from './styled'
import { ethers } from 'ethers'
import { configs } from 'apps'
import nativeVaultABI from 'json/NativeVault.json'
import userApi from 'apis/userApi'
import { notification } from 'antd'
import { setCoinUser } from 'features/Balance/coinUser.slice'

interface SwapADILToKUDAModalProps {
  onCloseButtonClick: () => void
  coinKuda: number
  coinAdil: number
}

const defaultParams: SwapETokenRequest = {
  amount: ''
}

const contract = {
  tokenGate: configs.tokenGate
}

function SwapADILToKUDAModal({ onCloseButtonClick, coinKuda, coinAdil }: SwapADILToKUDAModalProps) {
  const dispatch = useAppDispatch()
  const [params, setParams] = useState<SwapETokenRequest>(defaultParams)
  const [valueMARE, setValueInput] = useState<string>('')
  const amountInput = useDebounce<string>(valueMARE, constants.DEBOUNCE_TIME)
  const [isModalResultHorseOpen, toggleIsModalResultHorseOpen] = useToggle(false)
  const [messageError, setMessageError] = useState<string>('')
  const [titleBalace, setTitleBalance] = useState<string>('')
  const [flagButtonOk, setFlagButtonOk] = useState<boolean>(false)
  const [reInputExchange, setReInputExchange] = useState<boolean>(false)
  const [confirmSwap, setConfirmSwap] = useToggle(false)
  const [watchConnectWallet, setWatchConnectWallet] = useState<boolean>(false)
  const refInput = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()

  //start: exchange adil
  const [openInProgressBalanceModal, toggleOpenInProgressBalanceModal] = useToggle(false)
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const messageSuccess = t(`${NOTIFICATION_MESSAGE}.transferredSuccess`)
  const messageFailed = t(`${NOTIFICATION_MESSAGE}.transferredFailed`)
  const messageNotEnoughWithdraw = t(`${NOTIFICATION_MESSAGE}.notEnoughAdil`)

  const handleSearchValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const inputCoin: any = justNumbers(e.target.value)
    setValueInput(() => inputCoin)

    // const inputCoin = parseInt(e.target.value)
    const compareCoinADILInput = inputCoin > coinAdil

    if (compareCoinADILInput) {
      setValueInput((e.target.value = ''))
      setTitleBalance('warning')
      toggleIsModalResultHorseOpen(true)
      setMessageError(messageNotEnoughWithdraw)
      setReInputExchange(true)
      setWatchConnectWallet(false)
    }
    setWatchConnectWallet(false)
    // setValueInput(e.target.value.replace(/^0+/, ''))
  }

  useEffect(() => {
    if (refInput.current?.value === '') {
      setWatchConnectWallet(true)
    }
  })

  const handleCloseConfirmSwap = () => setConfirmSwap(false)

  useUpdateEffect(() => {
    setParams({ ...params, amount: amountInput })
  }, [amountInput])

  const handleSwapADILToKUDA = () => {
    const cannotInputValueZero = `value does not match`
    if (Number(amountInput) === 0) {
      toggleIsModalResultHorseOpen(true)
      setTitleBalance('warning')
      setMessageError(cannotInputValueZero)
      setFlagButtonOk(false)
    } else {
      setConfirmSwap(true)
      setTitleBalance('warning')
    }
  }

  const handleOk = () => {
    if (flagButtonOk === true) {
      setConfirmSwap(false)
      useReloadCurrentPage()
    } else {
      toggleIsModalResultHorseOpen(false)
    }
  }

  const handleOkExchange = () => {
    toggleIsModalResultHorseOpen(false)
  }

  const handleOkConfirm = async () => {
    handleCloseConfirmSwap()
    const [error, result] = await handleAsyncRequest(exchangeApi.postDepositAdil(params))

    if (error) {
      setTitleBalance('failed!')
      toggleIsModalResultHorseOpen(true)
      setMessageError(messageFailed)
    }
    if (!result) return

    if (result.code === 200) {
      toggleOpenInProgressBalanceModal(true)
      // setWatchConnectWallet(true)
      await exchangeAdilContract(result?.data)
    }
  }

  const exchangeAdilContract = async (value: resultItemNFT) => {
    if (!value) return
    try {
      const ethContract = new ethers.Contract(contract.tokenGate, nativeVaultABI.contract.abi, signer)
      try {
        const tx = await ethContract.deposit(value?.nonce, {
          gasPrice: convertGasPrice(value.gas_price),
          value: ethers.utils.parseEther(amountInput.toString())
        })
        if (tx.hash) {
          checkNonceContract(value.nonce)
        }
      } catch (err: any) {
        transactionFaild(err?.error?.data?.message)
        toggleOpenInProgressBalanceModal(false)
      }
    } catch (err: any) {
      transactionFaild(err?.error?.data?.message)
      toggleOpenInProgressBalanceModal(false)
    }
  }

  const checkNonceContract = async (nonce: string) => {
    let checkNonceExits = null as any
    let x = 0
    const intervalID = setInterval(async () => {
      const [, result] = await handleAsyncRequest(exchangeApi.postCheckNonceExchangeAdil({ nonce }))
      if (!result) return
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        transactionSuccess()
        toggleIsModalResultHorseOpen(true)
        setTitleBalance('success!')
        setMessageError(messageSuccess)
        setFlagButtonOk(true)
        fetchCoinUser()
      }

      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        transactionFaild(t(`${LENDING_MESSAGE}.failedTransaction`))
      }

      if (++x === 10) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          clearInterval(intervalID)
          toggleOpenInProgressBalanceModal(false)
          transactionFaild(t(`${NOTIFICATION_MESSAGE}.transferredPending`))
        }
      }
    }, timeCheckNonce)
  }

  const transactionSuccess = () => {
    toggleOpenInProgressBalanceModal(false)
    notification.success({
      message: 'SUCCESS',
      description: `Exchange successfully!`,
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })
  }

  const transactionFaild = (value: any) => {
    toggleOpenInProgressBalanceModal(false)
    notification.error({
      message: 'ERROR',
      description: value ?? 'Sorry! Your transaction has failed. Please go back and try again.',
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-error',
      icon: <></>
    })
  }

  const fetchCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return

    dispatch(setCoinUser(resultCoinUser.data))
  }

  const handleDisabledBtnConnect = useCallback(() => {
    if (valueMARE) {
      return false
    }

    return true
  }, [valueMARE])

  const handleDisableBtn = useCallback(() => {
    if (watchConnectWallet === true) {
      return true
    }

    return false
  }, [watchConnectWallet])

  return (
    <Modal>
      <SwapADILToKUDAModalStyled>
        <button className='close-btn p-0 position-absolute' role='button' onClick={onCloseButtonClick}>
          <img src={CLOSE_BTN} alt='close' />
        </button>
        <div className='title-exchange d-flex justify-content-between'>
          <div className='withdraw text-uppercase'>{'exchange'}</div>
          <div className='claim'>{t(`${NOTIFICATION_MESSAGE}.getYourEHtc`)}</div>
        </div>
        <div className='merah'>
          <img src={UP} alt='' />
          <img src={ICON_TON_CHAIN} alt='' className='game-token-merah' />
          <input
            className='value-merah search-input flex-grow-1'
            value={valueMARE}
            placeholder='0'
            onChange={handleSearchValueChanged}
            maxLength={10}
            disabled={isModalResultHorseOpen}
          />
          <span className='value-balance'>
            {t(`${NOTIFICATION_MESSAGE}.balance`)}:{coinAdil > 0 ? coinAdil : 0}
          </span>
          <span className='title-merah'>{CURRENCY_TYPE.TON}</span>
        </div>
        <div className='text-center mt-1 mb-3'>
          <img src={CIRCEL_ARROW_DOWN_PRIMARY} alt='' />
        </div>
        <div className='merah'>
          <img src={UP} alt='' />
          <img src={GAME_TOKEN_KUDA} alt='' className='game-token-merah' />
          <input
            ref={refInput}
            className='value-merah search-input flex-grow-1'
            placeholder='0'
            value={valueMARE}
            maxLength={10}
            disabled
          />
          <span className='value-balance'>
            {t(`${NOTIFICATION_MESSAGE}.balance`)}:{coinKuda}
          </span>
          <span className='title-merah'>{'ETH'}</span>
        </div>
        <div className='notice-exchange text-center'>
          <div>{t(`${NOTIFICATION_MESSAGE}.exchangeRate`)}</div>
          <div>{t(`${NOTIFICATION_MESSAGE}.ADILToKuda`)}</div>
        </div>
        <div className='btn-connect-wallet text-center'>
          <button onClick={handleSwapADILToKUDA} disabled={handleDisableBtn()}>
            <img
              src={handleDisabledBtnConnect() ? DISABLE_CONNECT_WALLET_EXCHANGE : CONNECT_WALLET_EXCHANGE}
              alt='btn-connect-h2h'
            />
          </button>
        </div>
      </SwapADILToKUDAModalStyled>
      {confirmSwap && (
        <ResultHorseModal
          toggleIsModalOpen={setConfirmSwap}
          title={titleBalace}
          onCloseButtonClick={handleCloseConfirmSwap}
          onOk={handleOkConfirm}
          message={
            <span dangerouslySetInnerHTML={{ __html: t(`${NOTIFICATION_MESSAGE}.convertAdilToKuda`, { valueMARE }) }} />
          }
        />
      )}

      {isModalResultHorseOpen && (
        <ResultHorseModal
          title={titleBalace}
          onOk={reInputExchange === true ? handleOkExchange : handleOk}
          message={messageError}
          exchangeCoin={true}
        />
      )}

      {openInProgressBalanceModal && <InProgressBalanceModal title={'EXCHANGE ADIL IN PROGRESS'} />}
    </Modal>
  )
}

export default SwapADILToKUDAModal
