/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import exchangeApi from 'apis/exchange'
import { configs, constants } from 'apps'
import {
  CURRENCY_TYPE,
  decimalFormatCoin,
  maxInput,
  minInput,
  STATUS_TRANSACTION,
  timeCheckNonce
} from 'apps/constants'
import {
  ICON_ADIL,
  CIRCEL_ARROW_DOWN,
  CLAIM_DISABLED,
  CLAIM_ITEM,
  CLOSE_BTN,
  GAME_TOKEN_MERAH,
  UP,
  ICON_TON_CHAIN
} from 'assets/images'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { ResultHorseModal } from 'features/Race/components'
import { useDebounce, useReloadCurrentPage, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import nativeVaultAbi from 'json/NativeVault.json'
import { ClaimResponses, DepositRequest } from 'models'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'shared'
import { convertGasPrice, handleAsyncRequest, justNumbers, numberWithCommas } from 'utils/helper'
import InProgressBalanceModal from '../InProgress'
import WithdrawBIRUModalStyled from './styled'
import { getSigner } from 'utils/metamask'

interface WithdrawBIRUModalProps {
  toggleIsWithdrawModalOpen: (value: boolean) => void
  coinEmas?: number
  coinAdil?: string
  coinMerah: number
  coinADILLogic: number
}

const defaultParams: DepositRequest = {
  amount: ''
}

function WithdrawBIRUModal({ coinEmas, toggleIsWithdrawModalOpen, coinMerah }: WithdrawBIRUModalProps) {
  const [params, setParams] = useState<DepositRequest>(defaultParams)
  const [valueMARE, setValueInput] = useState<string>('')
  const debounceSearchValue = useDebounce<string>(valueMARE, constants.DEBOUNCE_TIME)
  const [resultExchangeClaim, setResultExchangeClaim] = useState<ClaimResponses>()
  const [isModalResultHorseOpen, toggleIsModalResultHorseOpen] = useToggle(false)
  const [messageError, setMessageError] = useState('')
  const [titleBalace, setTitleBalance] = useState('')
  const [reInputExchange, setReInputExchange] = useState(false)
  const [openInProgressBalanceModal, setOpenInProgressBalanceModal] = useToggle(false)
  const [flagButtonOk, setFlagButtonOk] = useState<boolean>(false)
  const [watchInProgress, setWatchInProgress] = useState<boolean>(false)
  const [watchConnectWallet, setWatchConnectWallet] = useState<boolean>(false)
  const [watchCloseBtnWhenMTOpen, setWatchCloseBtnWhenMTOpen] = useState<boolean>(false)
  const [isModalConfirmExchangeOpen, setModalConfirmExchangeOpen] = useState<boolean>(false)
  const [isConfirmExchange, setConfirmExchange] = useState<boolean>(false)
  const { t } = useTranslation()
  const contract = {
    tokenMARE: configs.tokenMARE,
    tokenGate: configs.tokenGate,
    tokenEMAS: configs.tokenEMAS
  }

  const messageSuccess = t(`${NOTIFICATION_MESSAGE}.transferredSuccess`)
  const messageFailed = t(`${NOTIFICATION_MESSAGE}.transferredFailed`)
  const messageNotEnoughWithdraw = t(`${NOTIFICATION_MESSAGE}.notEnoughMerah`)
  // const messageCantClaimBIRUNow = t(`${NOTIFICATION_MESSAGE}.cannotClaimBIRUNow`)

  const handleSearchValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const inputCoin: any = justNumbers(e.target.value)
    setValueInput(() => inputCoin)
    const compareCoinMERAHInput = inputCoin > coinMerah

    if (compareCoinMERAHInput) {
      setValueInput((e.target.value = ''))
      setTitleBalance('warning')
      toggleIsModalResultHorseOpen(true)
      setMessageError(messageNotEnoughWithdraw)
      setReInputExchange(true)
      setWatchConnectWallet(true)
    }

    setWatchConnectWallet(false)
  }

  useEffect(() => {
    if (debounceSearchValue === '') {
      setWatchConnectWallet(true)
    }
    setParams({ ...params, amount: debounceSearchValue })
  }, [debounceSearchValue])

  useEffect(() => {
    if (watchInProgress === true) {
      toggleIsModalResultHorseOpen(true)
      setTitleBalance('success!')
      setMessageError(messageSuccess)
      setFlagButtonOk(true)
      localStorage.removeItem(constants.TRANSACTION)
    }
  }, [watchInProgress])

  const handleClaimMarehToAdil = async () => {
    toggleIsModalResultHorseOpen(false)
    setWatchConnectWallet(true)
    const [error, result]: any = await handleAsyncRequest(exchangeApi.postMerahToAdilClaim(params))
    if (error) {
      const errorMessage = error?.message ?? messageFailed
      setTitleBalance('failed!')
      toggleIsModalResultHorseOpen(true)
      setMessageError(errorMessage)
      setFlagButtonOk(false)
    }
    if (!result) return
    if (result) {
      setResultExchangeClaim(result.data)
      setModalConfirmExchangeOpen(true)
      setConfirmExchange(true)
    }
  }

  const assignMetaMarkEmas = async () => {
    try {
      const { ethereum } = window
      if (!ethereum) return
      if (!resultExchangeClaim) return
      // const provider = new ethers.providers.Web3Provider(window.ethereum)
      // const signer = provider.getSigner()
      const [, signer] = await handleAsyncRequest(getSigner())
      if (!signer) return

      const coverBlockExpired = new BigNumber(resultExchangeClaim.block_expired).toFixed()
      const amountToClaim = new BigNumber(resultExchangeClaim.blockchain_amount).toFixed()
      const nativeVaultContract = new ethers.Contract(contract.tokenGate, nativeVaultAbi.contract.abi, signer)
      const tx = await nativeVaultContract.swapToken(
        {
          sender: resultExchangeClaim.owner,
          token: resultExchangeClaim.token,
          blockExpired: coverBlockExpired,
          amount: amountToClaim,
          nonce: resultExchangeClaim.nonce,
          v: resultExchangeClaim.v,
          r: resultExchangeClaim.r,
          s: resultExchangeClaim.s
        },
        { gasPrice: convertGasPrice(+resultExchangeClaim.gas_price) }
      )

      if (tx.hash) {
        await handlePostCheckNonceClaim()
      }
      setWatchConnectWallet(true)
      setOpenInProgressBalanceModal(true)
    } catch (err) {
      setTitleBalance('failed!')
      toggleIsModalResultHorseOpen(true)
      setMessageError(messageFailed)
      setFlagButtonOk(true)
      setWatchConnectWallet(false)
      setWatchCloseBtnWhenMTOpen(false)
      setConfirmExchange(false)
    }
  }

  const handlePostCheckNonceClaim = async () => {
    if (!resultExchangeClaim) return
    setWatchConnectWallet(true)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let checkNonceExits = null as any
    let x = 0
    let intervalID = setInterval(async () => {
      const nonce = resultExchangeClaim.nonce
      const [, result] = await handleAsyncRequest(exchangeApi.postCheckNonce({ nonce }))
      if (!result) return
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        clearInterval(intervalID)
        setWatchInProgress(true)
      }
      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        clearInterval(intervalID)
        setTitleBalance('failed!')
        setMessageError(messageFailed)
        toggleIsModalResultHorseOpen(true)
        setWatchInProgress(false)
        setFlagButtonOk(true)
      }

      if (++x === 10) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          clearInterval(intervalID)
          setTitleBalance('warning')
          toggleIsModalResultHorseOpen(true)
          setMessageError(t(`${NOTIFICATION_MESSAGE}.transferredPending`))
          setFlagButtonOk(true)
        }
      }
    }, timeCheckNonce)
  }

  useEffect(() => {
    if (isConfirmExchange === true) {
      assignMetaMarkEmas()
    }
  }, [isConfirmExchange])

  useEffect(() => {
    if (openInProgressBalanceModal === true) {
      handlePostCheckNonceClaim()
    }
  }, [openInProgressBalanceModal])

  const handleOk = () => {
    if (flagButtonOk === false) {
      toggleIsModalResultHorseOpen(false)
      setModalConfirmExchangeOpen(false)
    }
    if (flagButtonOk === true) {
      useReloadCurrentPage()
    }
  }

  const handleOkExchange = () => {
    toggleIsModalResultHorseOpen(false)
  }

  const handleDisabledBtnConnect = useCallback(() => {
    if (valueMARE) {
      return false
    }

    return true
  }, [valueMARE])

  const handleDisableBtn = useCallback(() => {
    if (watchConnectWallet === true) {
      return true
    }

    return false
  }, [watchConnectWallet])

  const handleCloseButtonClick = useCallback(() => {
    if (watchCloseBtnWhenMTOpen === true) {
      return toggleIsWithdrawModalOpen(true)
    }

    return toggleIsWithdrawModalOpen(false)
  }, [watchCloseBtnWhenMTOpen])

  return (
    <Modal>
      <WithdrawBIRUModalStyled>
        <button className='close-btn p-0 position-absolute' role='button' onClick={handleCloseButtonClick}>
          <img src={CLOSE_BTN} alt='close' />
        </button>
        <div className='title-exchange d-flex justify-content-between'>
          <div className='withdraw text-uppercase'>{'claim'}</div>
          <div className='claim'>{t(`${NOTIFICATION_MESSAGE}.claimAdil`)}</div>
        </div>
        <div className='merah'>
          <img src={UP} alt='' />
          <img src={GAME_TOKEN_MERAH} alt='' className='game-token-merah' />
          <input
            className='value-merah search-input flex-grow-1'
            value={valueMARE}
            placeholder='0'
            onChange={handleSearchValueChanged}
            maxLength={10}
            min={minInput}
            max={maxInput}
            disabled={isModalResultHorseOpen}
          />
          <span className='value-balance'>
            {t(`${NOTIFICATION_MESSAGE}.balance`)}: {numberWithCommas(Number(coinMerah.toFixed(decimalFormatCoin)))}
          </span>
          <span className='title-merah'>{CURRENCY_TYPE.BTC}</span>
        </div>
        <div className='text-center mt-1 mb-3'>
          <img src={CIRCEL_ARROW_DOWN} alt='' />
        </div>
        <div className='merah'>
          <img src={UP} alt='' />
          <img src={ICON_TON_CHAIN} alt='' className='game-token-merah' />
          <input
            className='value-merah search-input flex-grow-1'
            placeholder='0'
            value={valueMARE}
            maxLength={10}
            disabled
          />
          <span className='value-balance'>
            {t(`${NOTIFICATION_MESSAGE}.balance`)}: {coinEmas}
          </span>
          <span className='title-merah'>{CURRENCY_TYPE.TON}</span>
        </div>
        <div className='notice-exchange text-center'>
          <div>{t(`${NOTIFICATION_MESSAGE}.exchangeRate`)}</div>
          <div>{t(`${NOTIFICATION_MESSAGE}.MERAHToAdil`)}</div>
        </div>
        <div className='btn-connect-wallet text-center'>
          <button onClick={handleClaimMarehToAdil} disabled={handleDisableBtn()}>
            <img
              src={handleDisabledBtnConnect() || watchConnectWallet ? CLAIM_DISABLED : CLAIM_ITEM}
              alt='btn-connect-h2h'
            />
          </button>
        </div>
      </WithdrawBIRUModalStyled>

      {isModalConfirmExchangeOpen && (
        <InProgressBalanceModal confirmTransaction={true} title={t(`${NOTIFICATION_MESSAGE}.claimAdilProgress`)} />
      )}

      {isModalResultHorseOpen && (
        <ResultHorseModal
          title={titleBalace}
          onOk={reInputExchange === true ? handleOkExchange : handleOk}
          message={messageError}
          exchangeCoin={true}
        />
      )}

      {openInProgressBalanceModal && <InProgressBalanceModal title={t(`${NOTIFICATION_MESSAGE}.claimAdilProgress`)} />}
    </Modal>
  )
}

export default WithdrawBIRUModal
