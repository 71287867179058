/* eslint-disable @typescript-eslint/no-explicit-any */
import { links } from 'apps'
import { LEVEL_UP, VIEW_DOPING } from 'assets/images'
import { useAppSelector } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import _ from 'lodash'
import { GuildHorseInfo, LENDING_STATUS, LendingHorse, LendingHorseInfo } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { convertStatusLending, getGuildTag, shortenUserName } from 'utils/helper'
import EnergyBar from '../EnergyBar'
import VipIcon from 'features/components/VipIcon'
import HorseAvatarItem from 'features/Horse/components/HorseItem/HorseAvatar'
import { convertLongTime, convertShortDay } from 'utils/time'
import { NUMBER, STRING_ARMOR_SKIN } from 'apps/constants'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import HorseAvatarStyled from './styled'
import { getImageGender, getImageRegion } from 'utils/getLinkImage'
import ImageArmorSkin from 'features/components/ImageArmorSkin'

interface HorseAvatarProps {
  horse: LendingHorse
  lendingInfo?: LendingHorseInfo | GuildHorseInfo
  displayVip?: boolean
}

function HorseAvatar({ horse, lendingInfo, displayVip = true }: HorseAvatarProps) {
  const [firstTime, setFirstTime] = useState(NUMBER.ZERO)
  const [disableRecovery, setDisableRecovery] = useState<boolean>(false)
  const { pathname: currentPathname } = useLocation()
  const [pathname, setPathname] = useState('')
  const [horseHead, setHorseHead] = useState('')
  const [horseBody, setHorseBody] = useState('')
  const [horseLeg, setHorseLeg] = useState('')
  const [horseSkin, setHorseSkin] = useState('')

  const { t } = useTranslation()

  const isHorseVip = horse?.msp_details?.msp

  useEffect(() => {
    const slicePathName = currentPathname?.split('/').slice(NUMBER.ONE)[NUMBER.ZERO]
    setPathname(slicePathName)
  }, [currentPathname])

  const profile = useAppSelector(state => state.profile)
  const isActive = horse?.owner?.id === profile?.id
  useEffect(() => {
    if (horse.remaining_time_to_next_energy > NUMBER.ZERO) {
      const start_at = horse.remaining_time_to_next_energy
      setFirstTime(start_at)
    }
    if (horse) {
      horse?.weapon_items_equipped?.map((item: any) => {
        switch (item?.body_part) {
          case STRING_ARMOR_SKIN.HEAD: {
            return setHorseHead(STRING_ARMOR_SKIN.HEAD)
          }
          case STRING_ARMOR_SKIN.BODY: {
            return setHorseBody(STRING_ARMOR_SKIN.BODY)
          }
          case STRING_ARMOR_SKIN.LEG: {
            return setHorseLeg(STRING_ARMOR_SKIN.LEG)
          }
          case STRING_ARMOR_SKIN.SKIN: {
            return setHorseSkin(STRING_ARMOR_SKIN.SKIN)
          }
          default:
            break
        }
      })
    }
  }, [horse])

  const timer = () => setFirstTime(firstTime - NUMBER.THOUSAND)
  useEffect(() => {
    const id = setInterval(timer, NUMBER.THOUSAND)
    if (firstTime > NUMBER.ZERO) {
      setDisableRecovery(true)
    }
    if (firstTime <= NUMBER.ZERO) {
      setDisableRecovery(false)
      clearInterval(id)
    }
    return () => clearInterval(id)
  }, [firstTime])

  return (
    <HorseAvatarStyled>
      {!_.isEmpty(horse) && (
        <div className='horse-container'>
          <div className='top-horse'>
            <div className='horse'>
              <div className='horse-info'>
                <div className='horse-name'>
                  <span className={`${isActive ? 'color-hightlight' : 'color'}`}>{horse.name}</span>
                </div>
                <span className='horse-gender'>
                  <div className='icon-gender'>
                    <MemoizedLoadImageCommon srcImg={getImageGender(horse?.gender)} />
                  </div>
                </span>

                {isHorseVip && displayVip && (
                  <div className='vip__pass'>
                    <VipIcon />
                  </div>
                )}
              </div>
              {pathname === links.specialRace.index() ? (
                <div className='horse-name-info'>
                  <div className='owner-name'>
                    {' '}
                    <span className='title'> OWNER:</span>{' '}
                    <span className='color-yellow'>{getGuildTag(horse?.owner?.guild_tag)}</span>{' '}
                    {shortenUserName(horse?.user?.name)}{' '}
                  </div>
                  <div className='token-id'>
                    {' '}
                    <span className='title'>TOKEN ID:</span> {horse?.token_id}{' '}
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className='horse-lending'>
                <div>{convertStatusLending(lendingInfo?.lending_status)}</div>
                {lendingInfo?.lending_status === LENDING_STATUS.Available && isActive && (
                  <div className='horse-level'>
                    <img src={LEVEL_UP} alt='horse-level' className='background-level' />
                    <span
                      className={`horse-level-number ${
                        horse?.level?.level.toString().length > NUMBER.ONE ? 'level-length-max' : ''
                      }`}
                    >
                      {horse?.level?.level}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <HorseAvatarItem
            horse={horse}
            name={horse?.name}
            avatar={horse?.avatar}
            items={horse?.weapon_items_equipped}
          />
          <div className='profile__image__armor'></div>
          <div className='energy-container'>
            <EnergyBar
              maxEnergy={horse?.max_energy || NUMBER.ONEHUNDRED}
              currentEnergy={horse?.current_energy || NUMBER.ZERO}
              firstTime={firstTime}
              disableRecovery={disableRecovery}
            />
          </div>

          {lendingInfo?.start_date && (
            <div className='time-container'>
              <div className='time'>
                <div className='title'> Start At: </div>
                {/* <div className='day-time'> {lendingInfo?.start_date} </div> */}
                <div className='day-time'>
                  {convertShortDay(lendingInfo?.start_date * NUMBER.THOUSAND)}{' '}
                  {convertLongTime(lendingInfo?.start_date * NUMBER.THOUSAND)}
                </div>
              </div>
              <div className='time'>
                <div className='title'> End At: </div>
                <div className='day-time'>
                  {convertShortDay(lendingInfo?.end_date * NUMBER.THOUSAND)}{' '}
                  {convertLongTime(lendingInfo?.end_date * NUMBER.THOUSAND)}
                </div>
              </div>
            </div>
          )}

          {pathname === links.specialRace.index() ? (
            <div className='d-flex justify-content-center'>
              <div className='detail-btn-container align-items-center mt-4'>
                <Link to={links.horse.detail(horse?.id)}>
                  <button className='detail-btn font-bold w-100 h-100'>
                    <span className='color-primary'>{t(`${NOTIFICATION_MESSAGE}.details`)}</span>
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </HorseAvatarStyled>
  )
}

export default HorseAvatar
