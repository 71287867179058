/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  STRING_ABILITY,
  STRING_BLOODLINE,
  STRING_CHARACTERISTIC,
  STRING_COLOR,
  STRING_GENDER,
  STRING_REGION,
  STRING_RUN_TYPE
} from 'apps/constants'
import {
  NFT_BODY,
  NFT_LEG,
  NFT_HEAD,
  NFT_SKIN,
  MALE_ICON,
  FEMALE_ICON,
  REGION_JAPAN,
  REGION_KOREA,
  REGION_VIETNAM,
  BL_HALO,
  BL_STAY_GOLD,
  BL_MR_PROSPECTOR,
  BL_PARTHOLON,
  BL_NEVER_BEND,
  BL_ROBERTO,
  BL_ZEDDAAN,
  BL_THE_TETRARCH,
  BL_SEATTLE_SLEW,
  BL_MILL_REEF,
  BL_CARO,
  BL_NUREYEV,
  BL_NORTHERN_DANCER,
  BL_BLUSHING_GROOM,
  BL_SADLER_WELLS,
  BL_ROUND_TABLE,
  BL_FRENCH_DEPUTY,
  BL_NATIVE_DANCER,
  BL_FORTY_NINER,
  BL_NORTHERN_TASTE,
  BL_NIJINSKY,
  BL_DANZIG,
  BL_LYPHARD,
  CHAR_WHIMP,
  CHAR_CALM,
  CHAR_EXCITED,
  CHAR_HATE_TO_LOSE,
  CHAR_OBIDENT,
  CHAR_PATIENCE,
  RT_FRONT_RUNNER,
  RT_MID_RUNNER,
  RT_TAIL_RUNNER,
  RT_TOP_RUNNER,
  AL_DIRT,
  AL_LONG,
  AL_MEDIUM,
  AL_SHORT,
  AL_TURF
} from 'assets/images'

const checkExistItem = (type: string, items: any) => {
  if (items?.length <= 0) return null

  const res = items?.find((item: any) => (item?.body_part || item?.bodyPart) === type)
  return res
}

const getImageItem = (type: string, items: any) => {
  const res = items?.find((item: any) => (item?.body_part || item?.bodyPart) === type)
  switch (type) {
    case 'HEAD':
      return res ? res.img : NFT_HEAD
    case 'BODY':
      return res ? res.img : NFT_BODY
    case 'LEG':
      return res ? res.img : NFT_LEG
    default:
      return res ? res.img : NFT_SKIN
  }
}

const isUseItem = (type: string, items: any) => {
  const res = items?.find((item: any) => (item?.body_part || item?.bodyPart) === type)
  switch (type) {
    case 'HEAD':
      return res ? true : false
    case 'BODY':
      return res ? true : false
    case 'LEG':
      return res ? true : false
    default:
      return res ? true : false
  }
}

const getImageGender = (type: any) => {
  switch (type) {
    case STRING_GENDER.MALE:
    case STRING_GENDER.Male:
      return MALE_ICON
    case STRING_GENDER.FEMALE:
    case STRING_GENDER.Female:
      return FEMALE_ICON
    default:
      return ''
  }
}

const getImageRegion = (type: any) => {
  switch (type) {
    case STRING_REGION.TOKYO:
      return REGION_JAPAN
    case STRING_REGION.SEOUL:
      return REGION_KOREA
    case STRING_REGION.HANOI:
      return REGION_VIETNAM
    default:
      return ''
  }
}

const getCharacteristic = (char: any) => {
  switch (char?.toUpperCase()) {
    case STRING_CHARACTERISTIC.WHIMP?.toUpperCase():
      return CHAR_WHIMP
    case STRING_CHARACTERISTIC.CALM?.toUpperCase():
      return CHAR_CALM
    case STRING_CHARACTERISTIC.EXCITED?.toUpperCase():
      return CHAR_EXCITED
    case STRING_CHARACTERISTIC.HATE_TO_LOSE?.toUpperCase():
      return CHAR_HATE_TO_LOSE
    case STRING_CHARACTERISTIC.OBEDIENT?.toUpperCase():
      return CHAR_OBIDENT
    case STRING_CHARACTERISTIC.PATIENCE?.toUpperCase():
      return CHAR_PATIENCE
    default:
      return ''
  }
}

const getRunType = (type: any) => {
  switch (type?.toUpperCase()) {
    case STRING_RUN_TYPE.FRONT_RUNNER?.toUpperCase():
      return RT_FRONT_RUNNER
    case STRING_RUN_TYPE.MID_RUNNER?.toUpperCase():
      return RT_MID_RUNNER
    case STRING_RUN_TYPE.TAIL_RUNNER?.toUpperCase():
      return RT_TAIL_RUNNER
    case STRING_RUN_TYPE.TOP_RUNNER?.toUpperCase():
      return RT_TOP_RUNNER
    default:
      return ''
  }
}

const getAbility = (type: any) => {
  switch (type?.toUpperCase()) {
    case STRING_ABILITY.DIRT?.toUpperCase():
      return AL_DIRT
    case STRING_ABILITY.LONG?.toUpperCase():
      return AL_LONG
    case STRING_ABILITY.MEDIUM?.toUpperCase():
      return AL_MEDIUM
    case STRING_ABILITY.SHORT?.toUpperCase():
      return AL_SHORT
    case STRING_ABILITY.TURF?.toUpperCase():
      return AL_TURF
    default:
      return ''
  }
}

const getImageBloodline = (bl: any) => {
  switch (bl?.toUpperCase()) {
    case STRING_BLOODLINE.BL_01?.toUpperCase(): {
      return BL_HALO
    }
    case STRING_BLOODLINE.BL_02?.toUpperCase(): {
      return BL_STAY_GOLD
    }
    case STRING_BLOODLINE.BL_03?.toUpperCase(): {
      return BL_MR_PROSPECTOR
    }
    case STRING_BLOODLINE.BL_04?.toUpperCase(): {
      return BL_PARTHOLON
    }
    case STRING_BLOODLINE.BL_05?.toUpperCase(): {
      return BL_NEVER_BEND
    }
    case STRING_BLOODLINE.BL_06?.toUpperCase(): {
      return BL_ROBERTO
    }
    case STRING_BLOODLINE.BL_07?.toUpperCase(): {
      return BL_ZEDDAAN
    }
    case STRING_BLOODLINE.BL_08?.toUpperCase(): {
      return BL_THE_TETRARCH
    }
    case STRING_BLOODLINE.BL_09?.toUpperCase(): {
      return BL_SEATTLE_SLEW
    }
    case STRING_BLOODLINE.BL_10?.toUpperCase(): {
      return BL_MILL_REEF
    }
    case STRING_BLOODLINE.BL_11?.toUpperCase(): {
      return BL_CARO
    }
    case STRING_BLOODLINE.BL_12?.toUpperCase(): {
      return BL_NUREYEV
    }
    case STRING_BLOODLINE.BL_13?.toUpperCase(): {
      return BL_NORTHERN_DANCER
    }
    case STRING_BLOODLINE.BL_14?.toUpperCase(): {
      return BL_BLUSHING_GROOM
    }
    case STRING_BLOODLINE.BL_15?.toUpperCase(): {
      return BL_SADLER_WELLS
    }
    case STRING_BLOODLINE.BL_16?.toUpperCase(): {
      return BL_ROUND_TABLE
    }
    case STRING_BLOODLINE.BL_17?.toUpperCase(): {
      return BL_FRENCH_DEPUTY
    }
    case STRING_BLOODLINE.BL_18?.toUpperCase(): {
      return BL_NATIVE_DANCER
    }
    case STRING_BLOODLINE.BL_19?.toUpperCase(): {
      return BL_FORTY_NINER
    }
    case STRING_BLOODLINE.BL_20?.toUpperCase(): {
      return BL_NORTHERN_TASTE
    }
    case STRING_BLOODLINE.BL_21?.toUpperCase(): {
      return BL_NIJINSKY
    }
    case STRING_BLOODLINE.BL_22?.toUpperCase(): {
      return BL_DANZIG
    }
    case STRING_BLOODLINE.BL_23?.toUpperCase(): {
      return BL_LYPHARD
    }
    default:
      return ''
  }
}

const getColorHorse = (cl: any) => {
  switch (cl?.toUpperCase()) {
    case STRING_COLOR.CL_01?.toUpperCase():
      return '#be1a21'
    case STRING_COLOR.CL_02?.toUpperCase():
      return '#e46a14'
    case STRING_COLOR.CL_03?.toUpperCase():
      return '#093e8c'
    case STRING_COLOR.CL_04?.toUpperCase():
      return '#2b853d'
    case STRING_COLOR.CL_05?.toUpperCase():
      return '#241917'
    case STRING_COLOR.CL_06?.toUpperCase():
      return '#7cb62e'
    case STRING_COLOR.CL_07?.toUpperCase():
      return '#198472'
    case STRING_COLOR.CL_08?.toUpperCase():
      return '#da3217'
    case STRING_COLOR.CL_09?.toUpperCase():
      return '#60afda'
    case STRING_COLOR.CL_10?.toUpperCase():
      return '#f0b81b'
    case STRING_COLOR.CL_11?.toUpperCase():
      return '#f4f3f9'
    case STRING_COLOR.CL_12?.toUpperCase():
      return '#631e7b'
    case STRING_COLOR.CL_13?.toUpperCase():
      return '#f1b9a8'
    case STRING_COLOR.CL_14?.toUpperCase():
      return '#4c2e14'
    case STRING_COLOR.CL_15?.toUpperCase():
      return '#c88a13'
    case STRING_COLOR.CL_16?.toUpperCase():
      return '#202b61'
    case STRING_COLOR.CL_17?.toUpperCase():
      return '#c9caca'
    case STRING_COLOR.CL_18?.toUpperCase():
      return '#f3e82a'
    case STRING_COLOR.CL_19?.toUpperCase():
      return '#c80e62'
    case STRING_COLOR.CL_20?.toUpperCase():
      return '#ab94c0'
    case STRING_COLOR.CL_21?.toUpperCase():
      return '#81262b'
    case STRING_COLOR.CL_22?.toUpperCase():
      return '#126fb5'
    case STRING_COLOR.CL_23?.toUpperCase():
      return '#edaec9'
    default:
      return ''
  }
}

export {
  checkExistItem,
  getImageItem,
  isUseItem,
  getImageGender,
  getImageRegion,
  getImageBloodline,
  getColorHorse,
  getCharacteristic,
  getRunType,
  getAbility
}
