/* eslint-disable @typescript-eslint/no-explicit-any */
import { CROWN_BRONZE_BORDER, CROWN_GOLD_BORDER, CROWN_SILVER_BORDER, ICON_HIGHLIGHT } from 'assets/images'
import classNames from 'classnames'
import { useAppSelector } from 'hooks'
import { StepHorse } from 'models'
import { getImageRegion } from 'utils/getLinkImage'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import HorseRankBoxStyled from './styled'
import {
  HORSE_BODY_BLACK,
  HORSE_BODY_BLUE,
  HORSE_BODY_BROWN,
  HORSE_BODY_CYAN,
  HORSE_BODY_DARKBLUE,
  HORSE_BODY_GREEN,
  HORSE_BODY_ORANGE,
  HORSE_BODY_PINK,
  HORSE_BODY_PURPLE,
  HORSE_BODY_RED,
  HORSE_BODY_WHITE,
  HORSE_BODY_YELLOW
} from 'assets/images'

interface HorseRankBoxProps {
  horseName: string
  subAvatar: string
  currentRank: number
  gate: number
  ownerId?: number
  horse: StepHorse
  region: any
}

function HorseRankBox({ horseName, subAvatar, currentRank, gate, ownerId, horse, region = '' }: HorseRankBoxProps) {
  const profile = useAppSelector(state => state.profile)
  const isActive = ownerId === profile?.id

  const topRankLayout = () => {
    switch (currentRank) {
      case 0:
        return (
          <div className='rank-bg position-absolute '>
            <img src={CROWN_GOLD_BORDER} />
          </div>
        )
      case 1:
        return (
          <div className='rank-bg position-absolute '>
            <img src={CROWN_SILVER_BORDER} />
          </div>
        )
      case 2:
        return (
          <div className='rank-bg position-absolute '>
            <img src={CROWN_BRONZE_BORDER} />
          </div>
        )
      default:
        return ''
    }
  }

  const getAvatarImageSource = (horse_id: number | undefined) => {
    const horse_id_str = horse_id + ''
    const color = horse_id_str.charAt(horse_id_str.length - 1)
    switch (color) {
      case '1': {
        return HORSE_BODY_BLACK
      }
      case '2': {
        return HORSE_BODY_BLUE
      }
      case '3': {
        return HORSE_BODY_BROWN
      }
      case '4': {
        return HORSE_BODY_CYAN
      }
      case '5': {
        return HORSE_BODY_DARKBLUE
      }
      case '6': {
        return HORSE_BODY_GREEN
      }
      case '7': {
        return HORSE_BODY_ORANGE
      }
      case '8': {
        return HORSE_BODY_PINK
      }
      case '9': {
        return HORSE_BODY_PURPLE
      }
      case '0': {
        return HORSE_BODY_RED
      }
      case '11': {
        return HORSE_BODY_WHITE
      }
      case '12': {
        return HORSE_BODY_YELLOW
      }
      default:
        return HORSE_BODY_WHITE
    }
  }

  return (
    <HorseRankBoxStyled
      className={classNames(['position-absolute', isActive && 'active'])}
      currentRank={currentRank}
      isHover={document.querySelector(`.horse-active-${gate}`) ? true : false}
    >
      <div
        className={classNames([
          'horse-rank-box d-flex align-items-center top-rank position-relative',
          isActive && 'horse-rank-box-active'
        ])}
      >
        <div className='avatar-container d-flex align-items-center justify-content-center'>
          <img src={getAvatarImageSource(horse.id)} alt={horseName} className='avatar ' />
        </div>
        <div className={`gate-container custom d-flex align-items-center justify-content-center no-${gate}`}>
          <div className='gate-number font-bold'>{gate}</div>
        </div>
        <div className='name-container p-0 m-0'>
          <div className='horse-name color-white'>
            <span className='color-yellow'>{horse.guild ? `[${horse.guild.guild_tag}]` : ''} </span>
            {horseName}
          </div>
        </div>
        {horse.highlight && <img className='icon-highlight' src={ICON_HIGHLIGHT} alt='' />}
        {topRankLayout()}
      </div>
    </HorseRankBoxStyled>
  )
}

export default HorseRankBox
